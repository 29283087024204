var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-sheet",
    { staticClass: "border-radius--5px", attrs: { color: "buttonGrayLight" } },
    [
      _vm.isAlert
        ? _c(
            "div",
            { staticClass: "pa-5" },
            [
              _vm.message.title !== ""
                ? _c(
                    "div",
                    { staticClass: "d-flex align-center mb-2" },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "mr-1 ml-n1",
                          attrs: { color: "textDark" },
                        },
                        [_vm._v(" mdi-alert-circle-outline ")]
                      ),
                      _c(
                        "div",
                        { staticClass: "textDark--text font-weight-bold" },
                        [_vm._v(" " + _vm._s(_vm.message.title) + " ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.message.subtitle !== ""
                ? _c("div", { staticClass: "textLight--text" }, [
                    _vm._v(" " + _vm._s(_vm.message.subtitle) + " "),
                  ])
                : _vm._e(),
              _vm._t("default"),
              _vm._t("expansion"),
            ],
            2
          )
        : _c(
            "div",
            { class: ["text-center textLight--text", _vm.containerPadding] },
            [
              _c("div", { staticClass: "mew-heading-3 pb-1" }, [
                _vm._v(_vm._s(_vm.message.title)),
              ]),
              _c("div", { staticClass: "mew-body" }, [
                _vm._v(" " + _vm._s(_vm.message.subtitle) + " "),
                _c("span", { staticClass: "redPrimary--text" }, [
                  _vm._v(_vm._s(_vm.message.subtitleError)),
                ]),
              ]),
            ]
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }